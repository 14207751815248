export default [
  {
    title: 'Introduction and tooling',
    description: 'Learn about the tools you need to be successful as a Web Developer today. What is the difference between a Text Editor and a Word Processor? A web app and a website? Can you make mobile apps with HTML? Find out in this series of posts and get started by outfitting your utility belt of web development with the right tools.',
    posts: [
      {
        slug: 'why-start-html',
        subsection: '1.1',
        title: 'Get an overview of why HTML is a great language to start your journey into programming and technology',
      }, {
        slug: 'tools-web-development',
        subsection: '1.2',
        title: 'Install the right tools and software to convert your computer into a creative machine',
      }, {
        slug: 'text-editor-vscode',
        subsection: '1.3',
        title: 'Learn to use Visual Studio Code - the most popular text editor for every programmer'
      }, 
    ]
  },
  {
    title: 'Formatting Text',
    description: 'Words, words, words. Learn to format the foundational building blocks of communicating your message online. Add special characters to your text, format your text in different ways, and emphasize it in a way that search engines will understand.',
    posts: [
      {
        slug: 'html-and-dom',
        subsection: '2.1',
        title: 'Get an introduction to how HTML is translated to the browser using the Document Object Model (DOM)'
      }, {
        slug: 'organize-web-pages',
        subsection: '2.2',
        title: 'Understand what makes HTML a structural markup language that is good at organizing information'
      }, {
        slug: 'paragraph-vs-spans',
        subsection: '2.3',
        title: 'The two most popular tags for displaying text in HTML and their differences'
      }
    ]
  },
  {
    title: 'Presentational Elements',
    description: 'Enhance your HTML writing with more ways of presenting information through tables, lists, and more advanced forms of structuring your content.',
    posts: [
      {
        slug: 'multimedia-elements-html',
        subsection: '3.1',
        title: 'Embed Audio, Video, and external content into your webpages with multimedia elements'
      }, {
        slug: 'list-formatting',
        subsection: '3.2',
        title: 'Format text content into a hierarchy with lists in HTML'
      }, {
        slug: 'html-table-sudoku',
        subsection: '3.3',
        title: 'Finish ambitiously by creating a sudoku puzzle using tables in HTML'
      }
    ]
  }
];
