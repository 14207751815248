import React, { Component } from 'react';
import htmlCurriculum from '../../curriculum/html';
import SEO from '../../components/SEO';
import Layout from '../../components/layout';
import { 
  CurriculumPageContainer, SubjectTitle, ChapterContainer,
  ChapterTitle, ChapterDescription, SectionLink
} from '../../Views/components';

class HTMLPage extends Component {
  render() { 
    return (
      <Layout>
        <SEO
          title="HTML website building tutorials for students and beginners by Nowispow"
          description="HTML tutorials by Nowispow. Learn to build interactive websites and applications from scratch for free. Whether you are a student or wanting to start a career in the tech field, these tutorials will help you."
        />
        <CurriculumPageContainer>
          <SubjectTitle>A Complete Introduction to HTML</SubjectTitle>
          <hr />
            {htmlCurriculum.map(
              (section, index) => (
                <ChapterContainer key={index + '-' + section && section.title}>
                  <ChapterTitle>{section && section.title}</ChapterTitle>
                  <ChapterDescription>{section && section.description}</ChapterDescription>
                  {section && section.posts && section.posts.length ? 
                    section.posts.map(
                      (eachPost, postIdx) => (
                        <div key={postIdx}>
                          <br />
                          <span>{eachPost && eachPost.subsection} - </span><SectionLink to={'../' + (eachPost && eachPost.slug)}>{eachPost && eachPost.title}</SectionLink>
                        </div>
                      )
                    ) :
                    null
                  }
                </ChapterContainer>
              )
            )}
        </CurriculumPageContainer>
      </Layout>
    );
  }
}
 
export default HTMLPage;
